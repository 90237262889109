import React, { useContext, createContext, useState } from "react";

// The context object itself does not hold any information. 
// It represents which context other components read or provide.
// Typically, you will use SomeContext.Provider in components above to 
// specify the context value, and call useContext(SomeContext) in components
// below to read it. The context object has a few properties:
// * SomeContext.Provider lets you provide the context value to components.
// * SomeContext.Consumer is an alternative and rarely used way to read the 
// context value.
// The main advantages of using a context object instead of passing down props
// is that context is global for a subtree: allows you to share data across
// many levels of the component tree without having to pass props manually.
// (reduces prop drilling). You can put a Provider at a higher level in the
// tree, and all components underneath that level can access the data via
// useContext or Consumer.
// It is mainly useful when the same data (like user info, theme settings, 
// etc.) is needed by many components scattered across different levels of the
// tree.
const AuthContext = createContext<{
    token: TokenType | null;
    signedIn: boolean;
    setToken: (token: TokenType | null) => void;
    setSignedIn: (signedIn: boolean) => void;
}>({
    token: null,
    signedIn: false,
    setToken: () => {},
    setSignedIn: () => {},
});

// TODO: determine what fields to keep.
export type TokenType = {
    email: string;
    name: string;
    family_name: string;
    given_name: string;
    iss: string;
    exp: number;
    hd: string;
    picture: string;
    jti: string;
};

const AuthProvider = ({ children }) => {
    let tokenString = localStorage.getItem('token');
    let parsedToken: TokenType | null = null;
    if (tokenString !== null) {
        try{
            parsedToken = JSON.parse(tokenString) as TokenType;
        } catch (error) {
            console.error("Error parsing token: ", error);
        }
    }
    const [token, setToken] = useState<TokenType | null>(parsedToken);
    const [signedIn, setSignedIn] = useState(!!parsedToken); // true if parsedToken exists.


    return (
        <AuthContext.Provider value={
            { 
                token,
                setToken,
                signedIn,
                setSignedIn,
            }
        }>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
}
