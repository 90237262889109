import React from 'react';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { fetchGoogleCredentials } from '../apis/fetchGoogle.tsx';
import { useAuth, TokenType } from '../contexts/AuthProvider.tsx';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
    const navigate = useNavigate();
    const { setToken, setSignedIn } = useAuth();

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            console.log('success')
            fetchCredentials(tokenResponse);
        },
        onError: tokenResponse => {
            console.log(`error: ${tokenResponse}`)
        }
    })

    const fetchCredentials = async (credentialResponse) => {
        const credential = credentialResponse.credential;
        const response = await fetchGoogleCredentials(credential);
        const decoded_token = response.decoded_token;
        const tokenData = {
            email: decoded_token.email,
            name: decoded_token.name,
            family_name: decoded_token.family_name,
            given_name: decoded_token.given_name,
            iss: decoded_token.iss,
            exp: decoded_token.exp,
            picture: decoded_token.picture,
            jti: decoded_token.jti,
            hd: decoded_token.hd,
        };
        setToken(tokenData);
        localStorage.setItem("token", JSON.stringify(tokenData));
        setSignedIn(true);
    };

    return (
        <div className='flex flex-col min-h-screen'>
            <div className='flex flex-row justify-center items-center flex-grow p-3'> 
                    <div className="flex flex-col items-center bg-neutral-800 border border-neutral-600 p-4 rounded-xl">
                        <img 
                            src="logo.svg"
                            className="w-[90px] sm:w-[120px] m-4 rounded-md cursor-pointer duration-100 ease-in-out hover:bg-neutral-800"
                            onClick={() => navigate('/')}
                        />             
                        {/* <GoogleLogin
                            onSuccess={fetchCredentials}
                            onError={() => console.log('Login Failed')}
                            type="standard"
                            shape="pill"
                            size="large"
                            logo_alignment="left"
                        /> */}
                        <button
                            className='flex flex-row items-center p-2 gap-2 rounded-md font-DMSans bg-neutral-100 duration-100 ease-in-out hover:bg-neutral-300'
                            onClick={handleGoogleLogin}
                        >
                            <img
                                src={require('../images/g-logo.png')}
                                className='h-6'
                            />
                            Connect with Google
                        </button>
                    </div>
            </div>
        </div>
    );
};

export default SignUp;
