import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer.tsx";
import Header from "./Header.tsx";

const DefaultLayout = () => {

    return(
        <div>
            <Header/>
            {/* Per react router DOM's doc:
            an <Outlet> should be used in parent route elements to render their child route
            elements. This allows nested UI to show up when child routes are rendered. */}
            <Outlet/>
            <Footer/>
        </div>
    );
}

export default DefaultLayout;