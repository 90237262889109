import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuOutlined, DatabaseOutlined, ApiOutlined, WalletOutlined, ShakeOutlined, DownOutlined, LogoutOutlined } from '@ant-design/icons';
import { Popover, PopoverTrigger, PopoverContent, Button } from "@nextui-org/react"
import { useAuth, TokenType } from '../contexts/AuthProvider.tsx';

const Header = () => {
    const navigate = useNavigate();
    return(
        <div className='sm:mx-44 my-3'>
            <div className='flex flex-row items-center justify-between h-30 w-full '>
                <div className='flex flex-row'>
                    <img 
                        src="logo.svg"
                        className="w-[90px] sm:w-[120px] rounded-md cursor-pointer duration-100 ease-in-out hover:bg-neutral-800"
                        onClick={() => navigate('/')}
                    />
                </div>

                <div className='hidden sm:flex flex-row text-white gap-3'>
                    <button
                        className='p-2 duration-200 ease-in-out rounded-md hover:bg-neutral-800'
                        onClick={() => navigate('/signup')}
                    >
                        Log In
                    </button>
                    <button
                        className='p-2 duration-200 ease-in-out text-black bg-white rounded-md hover:bg-neutral-300'
                        onClick={() => navigate('/signup')}    
                    >
                        Sign Up
                    </button>
                </div>
                <Dropdown />
            </div>
        </div>
    );
}

const Dropdown = () => {
    const navigate = useNavigate();
    const { token, setToken, signedIn, setSignedIn } = useAuth();

    const handleLogOut = () => {
        localStorage.removeItem("token");
        setToken(null);
        setSignedIn(false);
        navigate("/");
    }
    if (!signedIn) {
        return <></>
    }
    return (
    <Popover placement='bottom-end' showArrow={true}>
        <PopoverTrigger>
            <img src={token?.picture} className="rounded-full size-7"/>
        </PopoverTrigger>
        <PopoverContent>
            <div className='flex flex-col gap-0 rounded-lg bg-neutral-800 p-2 text-white text-sm'>
                <div className="text-xs text-neutral-400 mx-auto">{token?.email}</div>
                <div
                    className='flex flex-row justify-between cursor-pointer p-1 rounded-md hover:bg-neutral-600 duration-200 ease-in-out'
                    onClick={() => navigate('/dashboard')}
                >
                    <p>Dashboard</p>            
                    <DatabaseOutlined className='ml-2'/>
                </div>
                {/* <div 
                    className='flex flex-row justify-between cursor-pointer p-1 hover:rounded-md hover:bg-neutral-600 duration-200 ease-in-out'
                    onClick={() => navigate(`/billing/${token?.email}`)}
                >
                    <p>Billing</p>            
                    <WalletOutlined className='ml-2'/>
                </div> */}
                <div className='flex flex-row justify-between cursor-pointer p-1 hover:rounded-md hover:bg-neutral-600 duration-200 ease-in-out' onClick={handleLogOut}>
                    <p className='text-red-700'>Log Out</p>
                    <LogoutOutlined className='ml-2 text-red-700'/>
                </div>
            </div>
        </PopoverContent>
    </Popover>
    );
}

export default Header;