import React, { useState, useEffect } from 'react';
import { DeleteOutlined, HeartOutlined, ExportOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

// using dummy startups
import { startups } from '../components/DummyStartUps.tsx';

const Dashboard = () => {
    const [sortField, setSortField] = useState("annual_revenue");
    const [sortOrder, setSortOrder] = useState(-1); // 1: ascending, -1: descending
    const [tableData, setTableData] = useState(startups);

    const handleSort = (field: string) => {
        setSortField(field);
        setSortOrder(-1 * sortOrder);  // flip the sort order.

        const sorted = [...tableData].sort((a, b) => {
            return (
                (b[sortField] - a[sortField]) * sortOrder
            );
        });
        setTableData(sorted);
    }
    return (
        <div className="overflow-x-auto px-2 py-2 bg-neutral-900">
            <div className="sm:mx-20 mt-3 overflow-hidden flex flex-col items-center">
                <table className="w-full shadow-md text-sm">
                    <thead className="text-xs uppercase text-white">
                        <tr>
                            <th scope="col" className="p-4">
                                Desc.
                            </th>
                            {SortableTableHeadCell("Annual rev.", "annual_revenue", handleSort)}
                            {SortableTableHeadCell("Num. Cust.", "number_of_customers", handleSort)}
                            {SortableTableHeadCell("Funding", "current_funding", handleSort)}
                            {SortableTableHeadCell("TAM", "market_size", handleSort)}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData.map((startup) => { return TableRow(startup) })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Dashboard;

const SortableTableHeadCell = (label: string, field: string, handleSort: (field: string) => void) => {
    return (
    <th scope="col" className="px-1">
        <div className="flex items-center cursor-pointer" onClick={() => handleSort(field)}>
            <div className="text-wrap">{label}</div>
            <svg className="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"/>
            </svg>
        </div>
    </th>
    );
}

const TableRow = (startup) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/deck/${startup.id}`);
    }
    return (
        <tr 
            className="odd:bg-neutral-800 even:bg-neutral-700 border-b border-zinc-700 text-white cursor-pointer font-DMSans sm:h-16"
            // onClick={handleClick}
            key={startup.id}
        > 
            <td className="px-1">
                <div className="flex flex-col">
                    <p className='font-bold'>{startup.emoji} {startup.name}</p>
                    <p className="line-clamp-4 text-slate-300">{startup.description}</p>
                </div>
            </td>
            <td className="text-center px-1">
                <p>{convert(startup.annual_revenue)}</p>
            </td>
            <td className="text-center px-1">
                <p>{convert(startup.number_of_customers)}</p>
            </td>
            <td className="text-center px-1">
                <p>{convert(startup.current_funding)}</p>
            </td>
            <td className="text-center px-1">
                <p>{convert(startup.market_size)}</p>
            </td>
        </tr>
    )
}


// StartUpModal is a modal component that slides up when the user clicks on a table row.
interface StartUpModalProps {
  startup: any;
  show: boolean;
  setShow: (show: boolean) => void;
}

const StartUpModal = ({ startup, show, setShow }: StartUpModalProps) => {
    const tdStyle = "px-3"
    const rowStyle = "odd:bg-neutral-800 even:bg-neutral-700 border-b border-zinc-700 h-7";
    return (
        // overlay container. start-up modal is inside.
        // <div className={`fixed overflow-y-auto inset-0 w-full h-full bg-neutral-900 bg-opacity-70 transition-opacity duration-150 transform transition-transform ${
        //     show ? 'opacity-100 pointer-events-auto translate-y-0' : 'opacity-0 pointer-events-none translate-y-full'
        // }`}
            
        // >
            <div className={`w-full sm:w-2/3 md:w-1/2 fixed top-0 sm:bottom-1 sm:right-1 transition-transform duration-150 ease-in-out ${show ? 'translate-x-0' : 'translate-x-full'}`}>
                
                { startup &&
                    <div className="flex flex-col bg-neutral-950 rounded-xl shadow-xl pointer-events-auto font-DMSans">
                        <div className="flex justify-between items-center py-3 px-4 border-b border-neutral-900">
                            <p id="hs-basic-modal-label" className="text-white font-semibold">
                            {startup.name}
                            </p>
                            <button type="button" className="size-6 inline-flex justify-center items-center gap-x-2 rounded-full text-white" onClick={() => setShow(false)}>
                                <span className="sr-only">Close</span>
                                <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M18 6 6 18"></path>
                                    <path d="m6 6 12 12"></path>
                                </svg>
                            </button>
                        </div>
                        <div className="flex flex-col sm:flex-row p-4 overflow-y-auto text-white">
                            <div className='flex flex-col p-2'>
                                <p className="text-xs uppercase text-slate-300">Description</p>
                                <p className="mt-1">{startup.description}</p>
                                <p className="mt-3 text-xs uppercase text-slate-300">Product synthesis</p>
                                <p className="mt-1">{startup.product_synthesis}</p>
                                <p className="mt-3 text-xs uppercase text-slate-300">Stats</p>
                                <div className="overflow-x-auto flex flex-col rounded-md mt-1">
                                <table className="w-full text-sm text-slate-300">
                                    <tbody>
                                        <tr className={rowStyle}>
                                            <td scope="row" className={tdStyle}>Industry</td>
                                            <td className={tdStyle}>{startup.industry}</td>
                                        </tr>
                                        <tr className={rowStyle}>
                                            <td scope="row" className={tdStyle}>Annual revenue</td>
                                            <td className={tdStyle}>${convert(startup.annual_revenue)}</td>
                                        </tr>
                                        <tr className={rowStyle}>
                                            <td scope="row" className={tdStyle}>Number of customers</td>
                                            <td className={tdStyle}>${convert(startup.number_of_customers)}</td>
                                        </tr>
                                        <tr className={rowStyle}>
                                            <td scope="row" className={tdStyle}>Current funding</td>
                                            <td className={tdStyle}>${convert(startup.current_funding)}</td>
                                        </tr>
                                        <tr className={rowStyle}>
                                            <td scope="row" className={tdStyle}>TAM</td>
                                            <td className={tdStyle}>${convert(startup.market_size)}</td>
                                        </tr>
                                        <tr className={rowStyle}>
                                            <td scope="row" className={tdStyle}>Last market cap</td>
                                            <td className={tdStyle}>${convert(startup.last_market_cap)}</td>
                                        </tr>
                                        <tr className={rowStyle}>
                                            <td scope="row" className={tdStyle}>Trying to raise</td>
                                            <td className={tdStyle}>${convert(startup.trying_to_raise)}</td>
                                        </tr>
                                        <tr className={rowStyle}>
                                            <td scope="row" className={tdStyle}>Burn rate</td>
                                            <td className={tdStyle}>${convert(startup.burn_rate)}</td>
                                        </tr>
                                        <tr className={rowStyle}>
                                            <td scope="row" className={tdStyle}>Expected revenue</td>
                                            <td className={tdStyle}>${convert(startup.expected_revenue)}</td>
                                        </tr>
                                        <tr className={rowStyle}>
                                            <td scope="row" className={tdStyle}>Year founded</td>
                                            <td className={tdStyle}>{startup.year_founded}</td>
                                        </tr>
                                        <tr className={rowStyle}>
                                            <td scope="row" className={tdStyle}>Roadmap</td>
                                            <td className={tdStyle}>{startup.team_members}</td>
                                        </tr>
                                        <tr className={rowStyle}>
                                            <td scope="row" className={tdStyle}>Headcount</td>
                                            <td className={tdStyle}>{startup.headcount}</td>
                                        </tr>
                                        <tr className={rowStyle}>
                                            <td scope="row" className={tdStyle}>Relevant links</td>
                                            <td className={tdStyle}>
                                                <ul>
                                                {startup.relevant_links.map((link, i) => {
                                                    // have to set `key` to avoid getting a warning.
                                                    return (
                                                        <li key={i} className="text-blue-500 underline"><a href={link}>{link}</a></li>
                                                    )
                                                })}
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            </div>
                            {/* <div className='flex flex-col p-2 ml-5'>
                                <button className='flex flex-row items-center gap-2 bg-white rounded text-black p-2'>
                                    Open email
                                    <ExportOutlined />
                                </button>
                                <div className='my-2 flex text-center flex-row w-full'>
                                    <div className='w-1/2 bg-gradient-to-r from-fuchsia-500 to-cyan-500 cursor-pointer rounded-md text-white'>
                                        <HeartOutlined />
                                    </div>
                                    <div className='w-1/2'>
                                        <DeleteOutlined />
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className='flex flex-row items-center justify-center m-1'>
                                <div className='mx-2'>
                                    <DeleteOutlined />
                                </div>
                                <div className='mx-2'>
                                    <HeartOutlined />
                                </div>
                            </div> */}
                        </div>
                    </div> 
            }
            </div>
        // </div>
    )
}


// helper function to convert number to a user-friendly value.
// e.g. 12000000 -> 12M
export function convert(val: number | null) {
    if (val == null) {
        return "";
    }
    // Thousands, millions, billions etc..
    let s = ["", "k", "M", "B", "T"];

    // Dividing the value by 3.
    let sNum = Math.floor(("" + val).length / 3);

    // Calculating the precised value.
    let sVal = parseFloat(
        (sNum != 0
            ? val / Math.pow(1000, sNum)
            : val
        ).toPrecision(2)
    );
    let sValStr = sVal.toString();
    if (sVal % 1 != 0) {
        sValStr = sVal.toFixed(1);
    }

    // Appending the letter to precised val.
    return sValStr + s[sNum];
}