import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { useAuth } from './contexts/AuthProvider.tsx';

import DefaultLayout from './components/DefaultLayout.tsx';
import SignUp from './pages/SignUp.tsx';
import Landing from './pages/Landing.tsx';
import Deck from './pages/decks/[id].tsx';

export default function App() {
  const { signedIn } = useAuth();
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GCP_CLIENT_ID}>
      <Routes>
        <Route path='/' element={<DefaultLayout />}>
            <Route path='/' element={<Landing />} />
            <Route path='/signup' element={<SignUp/>} />
            <Route path='/deck/:deck_id' element={<Deck/>} />
        </Route>
      </Routes>
    </GoogleOAuthProvider>
  )
}
