import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const navigate = useNavigate();

    return(
        <div className="flex flex-row justify-center items-center gap-5 p-10 border-t border-neutral-700 text-white mt-3">
            <div className='flex flex-col gap-3 text-sm items-center'>
                <img 
                    src="logo.svg"
                    className="w-[90px] sm:w-[120px] rounded-md cursor-pointer duration-100 ease-in-out hover:bg-neutral-800"
                    onClick={() => navigate('/')}
                />
                <p>Never miss an investment opportunity again.</p>
                <p>Copyright © 2024 - All rights reserved</p>
            </div>
        </div>
    );
}

export default Footer;