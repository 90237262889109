import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../components/Dashboard.tsx';
import PriceCard from '../components/PriceCard.tsx'
import { DownOutlined, ArrowRightOutlined, UpOutlined } from '@ant-design/icons';
import Divider from '../components/Divider.tsx';
import { Accordion, AccordionItem } from "@nextui-org/accordion";

const Landing = () => {     

    const navigate = useNavigate();  

    const faq = [
        { question: 'What do you do with my emails ?', answer: "When you receive an email, Google sends us a notification. We then check whetherit contains a pitch deck PDF or not. If it does, we pass it through our extraction engine and structure all the infointo a table, that you can access. If not, we simply ignore it." },
        { question: 'Do you store my emails ?', answer: 'No, that would be both a privacy risk and prohibitively expensive. We only store pitch deck PDFs sent to your email and the info we extract from them.' },
        { question: 'Can you send emails on my behalf ?', answer: 'No, we only have "read" access to your emails.' }
    ];

    const [arrowState, setArrowState] = useState(Array<boolean>(faq.length).fill(false));

    const toggleArrow = (index) => {
        const copy = arrowState.slice();
        copy[index] = !copy[index];
        setArrowState(copy);
    }

    return (
    <div className="w-full sm:max-w-7xl min-h-screen flex flex-col gap-2 my-3 mx-auto">
        <div className="flex flex-col mt-20 my-5 items-start justify-center">
            <h1 className="text-white font-DMSans font-extrabold text-5xl sm:text-7xl tracking-tight flex flex-col gap-3 items-start animate-fade-up">
                <span className="">Extract the essentials from pitch decks </span>
                <span className="whitespace-nowrap ">
                    <span className="mr-3 sm:mr-4 md:mr-5">in</span>
                    <span className="relative whitespace-nowrap">
                        <span className="absolute bg-neutral-50 -left-2 -top-1 -bottom-1 -right-2 md:-left-3 md:-top-0 md:-bottom-0 md:-right-3 -rotate-1"></span>
                        <span className="relative text-black">seconds</span>
                    </span>
                </span>                
            </h1>
            <h2 className="mt-4 text-slate-200 font-extrabold text-xl animate-fade-up">
                straight from your Gmail inbox
            </h2>
            {/* <div className="mt-4 text-slate-400 text-lg text-left">
                Extract financials, market size, team credentials. 
                Spend less time reading and focus on making better decisions.
                </div> */}
            <div className="flex mx-auto mt-8">
                <button
                    className='flex flex-row gap-3 p-3 font-DMSans text-xl font-semibold
                            rounded-lg bg-gradient-to-r from-fuchsia-500 to-cyan-500 cursor-pointer 
                            text-white duration-100 ease-in-out hover:opacity-80'
                    onClick={() => navigate('/signup')}
                >
                    <p>Stop missing opportunities today</p>
                    <ArrowRightOutlined />
                </button>
            </div>

            <div className="mt-10 flex items-end gap-2">
                <span className="text-xl text-slate-200 w-64 sm:w-80">
                    We automatically detect pitch decks sent to your Gmail inbox and transform them into structured data
                </span>
                <svg className="size-16 translate-y-12 mt-1" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.06113603136941 95.60964287493192">
                    <path d="M0.06 1.83 C7.33 6.23, 29.92 12.63, 41.7 24.54 C53.47 36.45, 66.56 65.03, 70.72 73.27 M-3.34 0.35 C4.97 5.23, 34.77 14.53, 46.99 27.13 C59.2 39.73, 66.78 67.71, 69.97 75.96" stroke="#fcbf23" stroke-width="4" fill="none"></path>
                    <path d="M55.54 55.06 C56.41 62.45, 60.89 64.09, 69.94 75.15 M53.89 55.58 C59.25 63.7, 65.08 70.11, 70.31 75.9" stroke="#fcbf23" stroke-width="4" fill="none"></path>
                    <path d="M71.71 49.49 C68.77 58.19, 69.48 61.12, 69.94 75.15 M70.06 50.02 C69.72 60.22, 69.82 68.59, 70.31 75.9" stroke="#fcbf23" stroke-width="4" fill="none"></path>
                </svg>
            </div>
        </div>

    <div className='my-3'>
        <Dashboard />
    </div>

    <Divider />

    {/* PRICING REGION */}
    <div className="flex my-3 w-full text-white">
        <div className="flex flex-col h-auto my-5 sm:my-1 sm:justify-center items-start w-full">
            <h1 id="#pricing" className="my-2 text-white font-DMSans font-extrabold text-3xl sm:text-4xl tracking-tight flex flex-col gap-3 items-start">
                Pricing
            </h1>
            <span className="text-xl">
                Only pay for what you use.
            </span>
            <span className="text-xl">Buy credits as needed, set a monthly limit, no subscription.</span>
            <div className="flex flex-col w-full items-center my-4">
                <PriceCard title="Classic" price={29.99} features={[
                    '10 mails extraction per day.',
                    'Insights',
                    'Ranking'
                ]} />
                <PriceCard title="Premium" price={59.99} features={[
                    '50 mails extraction per day.',
                    'Insights',
                    'Ranking',
                ]} />
        </div>


        </div>
    </div>

    <Divider />

    <div className="flex my-3" id="#faq">
        <div className="flex flex-col h-auto sm:my-1 sm:justify-center items-start w-11/12 mx-auto sm:mx-32 gap-8">
            <h1 className="text-white font-DMSans font-extrabold text-3xl sm:text-4xl tracking-tight flex flex-col items-start">
                Frequently Asked Questions
            </h1>
            <Accordion>
                {
                    faq.map((item, index) => (
                        <AccordionItem
                            key={index}
                            aria-label={item.question}
                            title={
                                <div className="flex flex-grow justify-between w-full">
                                    <span className="font-medium">{item.question}</span>
                                    {/* <span>
                                        {arrowState[index] ? <UpOutlined /> : <DownOutlined />}
                                    </span> */}
                                </div>
                            }
                            className="w-full text-white p-3"
                            onClick={() => toggleArrow(index)}
                            hideIndicator
                        >
                            <span>{item.answer}</span>
                        </AccordionItem>
                    ))
                }
            </Accordion>
            {/* <div className="flex flex-col">
                <span className="text-white font-bold text-xl">
                    What do you do with my emails?
                </span>
                <span className="text-white">
                    When you receive an email, Google sends us a notification. We then check whether
                    it contains a pitch deck PDF or not.
                    If it does, we pass it through our extraction engine and structure all the info
                    into a table, that you can access.
                    If not, we simply ignore it.
                </span>
            </div>
            <div className="flex flex-col">
                <span className="text-white font-bold text-xl">
                    Do you store my emails?
                </span>
                <span className="text-white">
                    <span className="font-bold">No</span>, that would be both a privacy risk and
                    prohibitively expensive.
                    We only store pitch deck PDFs sent to your email and the info we extract from them.
                </span>
            </div>
            <div className="flex flex-col">
                <span className="text-white font-bold text-xl">
                    Can you send emails on my behalf?
                </span>
                <span className="text-white">
                    <span className="font-bold">No</span>, we only have "read" access to your emails.
                </span>
            </div> */}
        </div>
    </div>

    {/* <div className="w-11/12 sm:mx-32 mx-auto min-h-24">
        <div className="text-neutral-200">
            A question? Send us an email at hello@get-haystack.com
        </div>

        <div className="mt-5 text-white font-bold">
            allright... get outta here...
        </div>
    </div> */}
    </div>
    
);
}

export default Landing;