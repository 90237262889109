import axios from 'axios';

// environment variables can be added to a .env file. They will be
// embedded at build time. If you change them, you need to restart the 
// dev server.
// Only variables that start with REACT_APP_ will be embedded into
// the JavaScript bundle.
// The .env file should be added to your .gitignore to avoid exposing
// sensitive information.

export const fetchGoogleCredentials = async (credentials: string) => {
    const BACKEND_URL = `http://0.0.0.0:8000/token/verify-token`

    // make a request to the backedn to verify the encrypted token.
    try {
        const response = await axios.post(
            BACKEND_URL,
            {},
            {
                headers: {
                    'Authorization': `Bearer ${credentials}`,
                }
            }
        );

        return {
            decoded_token: response.data.decoded_token
        }
    } catch(error) {
        throw error;
    }
}
