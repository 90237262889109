import React from 'react';

import { useParams } from 'react-router-dom';

import { convert } from '../../components/Dashboard.tsx';
import { startups, StartupType } from '../../components/DummyStartUps.tsx';



const Deck = () => {
    const { deck_id } = useParams();

    const startup: StartupType | undefined = startups.find(startup => startup.id === deck_id);

    if (!startup) {
        return <div>Startup not found</div>;
    }

    const tdStyle = "px-3"
    const rowStyle = "odd:bg-neutral-800 even:bg-neutral-700 border-b border-zinc-700 h-7";
    return (
            <div className="w-11/12 sm:w-2/3 md:w-1/2 mx-auto">
                <div className="flex flex-col">
                    <div className="flex justify-between items-center py-3 px-4 border-b border-neutral-900">
                        <div className="text-4xl text-slate-200 font-extrabold tracking-tight">{startup.name}</div>
                    </div>
                    <div className="p-4 overflow-y-auto text-white">
                        <p className="text-xs uppercase text-slate-300">Description</p>
                        <p className="mt-1">{startup.description}</p>
                        <p className="mt-3 text-xs uppercase text-slate-300">Product synthesis</p>
                        <p className="mt-1">{startup.product_synthesis}</p>
                        <p className="mt-3 text-xs uppercase text-slate-300">Stats</p>
                        <div className="overflow-x-auto flex flex-col rounded-md mt-1">
                        <table className="w-full text-sm text-slate-300">
                            <tbody>
                                <tr className={rowStyle}>
                                    <td scope="row" className={tdStyle}>Industry</td>
                                    <td className={tdStyle}>{startup.industry}</td>
                                </tr>
                                <tr className={rowStyle}>
                                    <td scope="row" className={tdStyle}>Annual revenue</td>
                                    <td className={tdStyle}>${convert(startup.annual_revenue)}</td>
                                </tr>
                                <tr className={rowStyle}>
                                    <td scope="row" className={tdStyle}>Number of customers</td>
                                    <td className={tdStyle}>${convert(startup.number_of_customers)}</td>
                                </tr>
                                <tr className={rowStyle}>
                                    <td scope="row" className={tdStyle}>Current funding</td>
                                    <td className={tdStyle}>${convert(startup.current_funding)}</td>
                                </tr>
                                <tr className={rowStyle}>
                                    <td scope="row" className={tdStyle}>TAM</td>
                                    <td className={tdStyle}>${convert(startup.market_size)}</td>
                                </tr>
                                <tr className={rowStyle}>
                                    <td scope="row" className={tdStyle}>Last market cap</td>
                                    <td className={tdStyle}>${convert(startup.last_market_cap)}</td>
                                </tr>
                                <tr className={rowStyle}>
                                    <td scope="row" className={tdStyle}>Trying to raise</td>
                                    <td className={tdStyle}>${convert(startup.trying_to_raise)}</td>
                                </tr>
                                <tr className={rowStyle}>
                                    <td scope="row" className={tdStyle}>Burn rate</td>
                                    <td className={tdStyle}>${convert(startup.burn_rate)}</td>
                                </tr>
                                <tr className={rowStyle}>
                                    <td scope="row" className={tdStyle}>Expected revenue</td>
                                    <td className={tdStyle}>${convert(startup.expected_revenue)}</td>
                                </tr>
                                <tr className={rowStyle}>
                                    <td scope="row" className={tdStyle}>Year founded</td>
                                    <td className={tdStyle}>{startup.year_founded}</td>
                                </tr>
                                <tr className={rowStyle}>
                                    <td scope="row" className={tdStyle}>Roadmap</td>
                                    <td className={tdStyle}>{startup.team_members}</td>
                                </tr>
                                <tr className={rowStyle}>
                                    <td scope="row" className={tdStyle}>Headcount</td>
                                    <td className={tdStyle}>{startup.headcount}</td>
                                </tr>
                                <tr className={rowStyle}>
                                    <td scope="row" className={tdStyle}>Relevant links</td>
                                    <td className={tdStyle}>
                                        <ul>
                                        {startup.relevant_links?.map((link, i) => {
                                            // have to set `key` to avoid getting a warning.
                                            return (
                                                <li key={i} className="text-blue-500 underline"><a href={link}>{link}</a></li>
                                            )
                                        })}
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div> 
            </div>
    )
}

export default Deck;