import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './contexts/AuthProvider.tsx';
import ScrollToTop from './components/ScrollToTop.tsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <StrictMode> lets you find common bugs in your components early during development.
  // https://react.dev/reference/react/StrictMode
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        {/* 
        custom component to scroll to the top of the page when a new route opens.
        react-router-dom has the ScrollRestoration component but it only works with data routers.
        */}
        <ScrollToTop />
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
