import React from 'react';
import { CheckOutlined, StockOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

type PriceCardProps = {
    title: string,
    price: number,
    features: Array<string>,
}

const PriceCard = ({ title, price, features }: PriceCardProps) => {
    
    const navigate = useNavigate();

    return (
        <div className='flex flex-col w-full justify-between h-auto sm:h-56 sm:flex-row rounded-xl border-2 border-gray-500 text-white p-1 my-3 font-DMSans'>
            <div className='flex flex-col m-px p-2 items-center justify-center h-full w-full'> 
                <p className='text-2xl font-semibold'>What's included</p>
                <div className='flex flex-col items-start'>
                {
                    features.map((feature, index) => (
                        <div key={index} className='flex flex-row gap-2'>
                            <CheckOutlined style={{ color: 'blue' }} />
                            <p className='text-xl'>{feature}</p>
                        </div>
                    ))
                }
                </div>
            </div>

            <div className='flex flex-col justify-center items-center bg-neutral-800 p-3 rounded-lg m-px h-full w-full'>
                <div className='flex flex-col justify-center items-center flex-wrap'>
                    <h1 className="font-extrabold text-5xl sm:text-5xl tracking-wide items-start">
                        ${price}
                    </h1>
                    <p className='text-xl sm:text-xl font-semibold ml-2'>
                        USD / month
                    </p>
                    <p className='text-sm text-center text-neutral-400'>
                        <SafetyCertificateOutlined /> Safe payments powered by Stripe
                    </p>
                </div>

                <div
                    className='flex flex-row flex-wrap items-center justify-center my-2 p-2
                                rounded-lg bg-gradient-to-r from-fuchsia-500 to-cyan-500
                                cursor-pointer duration-100 ease-in-out hover:opacity-80'
                    onClick={() => navigate('/signup')}
                >
                    <p className='font-semibold text-center'>Get investments opportunities</p>
                    <StockOutlined className='text-2xl ml-1'/>
                </div>
            </div>
        </div>
    );
}

export default PriceCard;
